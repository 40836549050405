import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import backgroundImage from '../../assets/App-BG-Final.jpg';
import logoImage from '../../assets/TKM_Green.png';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function SignIn() {
  const [mobileNumber, setMobileNumber] = useState('');
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [countryCode, setCountryCode] = useState('+91'); // Default country code
  const [token, setToken] = useState(null);
  const [otp, setOtp] = useState(new Array(6).fill('')); // Array to hold OTP digits
  const inputRefs = useRef([]); // Initialize inputRefs using useRef

  const navigate = useNavigate();

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
      navigate('/dashboard');
    }
  }, [navigate]);

  const handleGetOtp = async () => {
    setShowOtpInput(true); // Shows OTP input after the OTP is sent
    const formattedNumber = mobileNumber.replace(/-/g, '');
    if (formattedNumber.length !== 10) {
      alert("Please enter a valid 10-digit mobile number");
      return;
    }

    try {
      const response = await fetch('https://api.kolkatajamaat.com/api/get_otp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ mobile: countryCode + formattedNumber }),
      });

      if (!response.ok) {
        setShowOtpInput(false);
        alert('This mobile number is not registered.');
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
    }
  };

  const formatMobileNumber = (value) => {
    const cleaned = ('' + value).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);

    if (match) {
      return [match[1], match[2], match[3]].filter(Boolean).join('-');
    }
    return value;
  };

  const handleMobileNumberChange = (e) => {
    const formattedNumber = formatMobileNumber(e.target.value);
    setMobileNumber(formattedNumber);
  };

  const handleOtpChange = (e, index) => {
    const { value } = e.target;
    if (/^[0-9]$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < 5 && value !== '') {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleBackspace = (e, index) => {
    if (e.key === 'Backspace') {
      const newOtp = [...otp];
      newOtp[index] = ''; // Clear the current input value
      setOtp(newOtp);

      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      const newOtp = [...otp];
      newOtp[index] = ''; // Clear the current box
      setOtp(newOtp);

      if (e.key === 'Backspace' && e.target.previousSibling) {
        e.target.previousSibling.focus();
      }

      e.preventDefault();
    }
  };

  const handleLogin = async (otpString) => {
    try {
      const formattedNumber = mobileNumber.replace(/-/g, ''); // Remove hyphens
      const response = await fetch(`https://api.kolkatajamaat.com/api/login/${otpString}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ mobile: countryCode + formattedNumber }),
      });

      if (response.ok) {
        const tokenData = await response.json();
        localStorage.setItem('token', tokenData.data.token);
        setToken(tokenData.data.token);
        navigate('/dashboard');
      } else {
        alert('Invalid OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-cover bg-center bg-[burlywood]" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="p-6 rounded-lg w-full max-w-md">
        <div className="flex justify-center mb-6 mt-[-50px]">
          <img
            src={logoImage}
            alt="TKM Logo"
            className="rounded-full object-cover"
            style={{ width: '80vw' }}
          />
        </div>
        <h1 className="text-2xl font-bold mb-4 text-center mt-[-25px] text-[#045c34]">TKM SCANNING</h1>

        <div className="mb-4 mt-[10px]">
          <div className="mb-4 flex mt-10">
            <select
              className="border rounded-l-md px-3 py-2 bg-gray-100 text-xl text-[darkslategrey] focus:outline-none"
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value)}
              hidden={showOtpInput}
            >
              <option value="+91">🇮🇳 +91</option>
            </select>
            <input
              type="text"
              className="w-full px-3 py-2 border rounded-r-md text-xl text-[darkslategrey] focus:outline-none"
              placeholder="Enter Mobile"
              value={mobileNumber}
              onChange={handleMobileNumberChange}
              maxLength="12"
              hidden={showOtpInput}
            />
          </div>
        </div>

        {showOtpInput && (
          <div className="mb-4">
            <div className="flex space-x-[11px] justify-center">
              {Array.from({ length: 6 }).map((_, index) => (
                <input
                  key={index}
                  type="tel"
                  maxLength={1}
                  className="w-14 h-14 border-2 border-gray-300 focus:shadow-md rounded-full text-center text-2xl focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-blue-500 transition ease-in-out duration-150"
                  value={otp[index]}
                  onChange={(e) => handleOtpChange(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  ref={(el) => (inputRefs.current[index] = el)}
                />
              ))}
            </div>
            <h1 className="text-2xl mb-4 text-center mt-10">Enter OTP</h1>
          </div>
        )}

        {!showOtpInput ? (
          <button
            className="flex items-center justify-center w-full bg-green-500 text-white py-2 rounded-md hover:bg-green-600 focus:outline-none mb-8 mt-5"
            onClick={handleGetOtp}
          >
            <FontAwesomeIcon icon={faWhatsapp} className="h-6 w-6 mr-2" />
            Get OTP
          </button>
        ) : (
          <button
            className="w-full bg-green-500 text-white py-2 rounded-md hover:bg-green-600 mt-4 mb-5 focus:outline-none mb-8"
            onClick={() => handleLogin(otp.join(''))}
          >
            Login
          </button>
        )}
      </div>
    </div>
  );
}

export default SignIn;
