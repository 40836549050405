import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import backgroundImage from '../assets/App-BG-Final.jpg';
import logoImage from '../assets/TKM_Green.png';

function Dashboard() {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [itsNumber, setItsNumber] = useState('');
  const [mumininData, setMumininData] = useState(null);
  const [recordCounts, setRecordCounts] = useState({
    total_record: 0,
    total_male_record: 0,
    total_female_record: 0,
  });
  const [currentDate, setCurrentDate] = useState(new Date());
  const [submissionStatus, setSubmissionStatus] = useState('');
  const [statusCode, setStatusCode] = useState(null);
  const [gender, setGender] = useState('');
  const navigate = useNavigate();
  const [showStatus, setShowStatus] = useState(false);
  
  // New state to track if the form is being submitted
  const [isSubmitting, setIsSubmitting] = useState(false);

  const itsNumberInputRef = useRef(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (!storedToken) {
      toast.error('You must be logged in to access the dashboard.');
      navigate('/signin');
      return;
    }

    const fetchEvents = async () => {
      try {
        const response = await fetch('https://api.kolkatajamaat.com/api/events', {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        });
        const result = await response.json();
        if (result && Array.isArray(result.data)) {
          setEvents(result.data);
        } else {
          setEvents([]);
        }
      } catch (error) {
        console.error('Error fetching events:', error.message);
        setEvents([]);
      }
    };

    fetchEvents();
  }, [navigate]);

  useEffect(() => {
    if (itsNumberInputRef.current) {
      itsNumberInputRef.current.focus();
    }
  }, []);

  const handleDashboardSubmit = async (e) => {
    e.preventDefault();
    
    // Avoid losing focus on submission by explicitly keeping the focus on the field
    if (itsNumberInputRef.current) {
      itsNumberInputRef.current.focus();
    }

    if (!selectedEvent || itsNumber.length !== 8 || !gender) {
      toast.error('Please ensure all fields are correctly filled.');
      return;
    }

    // Disable the submit button by setting isSubmitting to true
    setIsSubmitting(true);

    const storedToken = localStorage.getItem('token');
    try {
      const response = await fetch(`https://api.kolkatajamaat.com/api/get_scan`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        },
        body: JSON.stringify({ event_id: selectedEvent, its: itsNumber, gender: gender }),
      });

      const text = await response.text();
      if (text.trim().startsWith('{')) {
        const result = JSON.parse(text);
        setStatusCode(response.status);

        if (response.ok || response.status === 422) {
          setMumininData(result.data && result.data.length > 0 ? result.data[0] : null);

          // Check for record counts in both result.record_counts and result.data[0].record_counts
          if (result.record_counts && result.record_counts.length > 0) {
            setRecordCounts(result.record_counts[0]);
          } else if (result.data[0]?.record_counts && result.data[0].record_counts.length > 0) {
            setRecordCounts(result.data[0].record_counts[0]);
          } else {
            // Fallback to default values if no record counts exist
            setRecordCounts({
              total_record: 0,
              total_male_record: 0,
              total_female_record: 0,
            });
          }

          setSubmissionStatus(result.message);
          toast.success(result.message);
        } else {
          throw new Error(result.message || 'Failed to submit data');
        }
      } else {
        throw new Error('Server response was not valid JSON: ' + text);
      }
    } catch (error) {
      console.error('Submission failed:', error);
      toast.error(error.message);
      setSubmissionStatus(error.message);
      setStatusCode(500);
    } finally {
      setItsNumber(''); // Clear the input
      setShowStatus(true);
      
      // Re-enable the submit button after the response is received
      setIsSubmitting(false);

      // Focus again on the input field
      if (itsNumberInputRef.current) {
        itsNumberInputRef.current.focus(); // Keep the focus here after submission
      }
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    toast.info('Logged out successfully.');
    navigate('/signin');
  };

  const formattedTime = currentDate.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  });

  const formattedDate = currentDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  const isoDate = currentDate.toISOString().split('T')[0];

  const getStatusClass = () => {
    if (statusCode === 201) return 'bg-green-500 text-white';
    if (statusCode === 500) return 'bg-red-500 text-white';
    if (statusCode === 422) return 'bg-yellow-500 text-black';
    return 'text-green-800';
  };

  return (
    <div
      className="relative flex justify-center items-center h-screen w-full bg-cover bg-center"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundAttachment: 'fixed',
      }}
    >
      <div className="absolute inset-0 opacity-10"></div>

      <div className="relative z-10 p-6 max-w-md w-full rounded-lg bg-white/10 backdrop">
        <div className="flex items-center justify-between text-xs mb-4">
          <p className="bg-gray-700 text-white rounded-full px-3 py-1">
            <time dateTime={isoDate}>
              {formattedDate} | {formattedTime}
            </time>
          </p>
          <button
            onClick={handleLogout}
            className="bg-red-500 hover:bg-red-700 text-white rounded-full px-3 py-1"
          >
            Logout
          </button>
        </div>

        <div className="flex justify-center mb-6">
          <img
            src={logoImage}
            alt="TKM Logo"
            className="w-52 h-52 object-cover rounded"
          />
        </div>

        <h1 className="text-center text-2xl font-bold mb-4 text-green-800 py-2 rounded">
          TKM SCANNING
        </h1>

        {showStatus && (
          <h1 className={`text-center text-2xl font-bold mb-4 py-2 rounded ${getStatusClass()}`}>
            {submissionStatus}
          </h1>
        )}

        <form onSubmit={handleDashboardSubmit} className="space-y-4">
          <div>
            <select
              className={`w-full px-3 py-2 border rounded-lg bg-transparent focus:outline-none text-gray-700 ${
                !selectedEvent && 'bg-transparent text-gray-300'
              }`}
              value={selectedEvent}
              onChange={(e) => setSelectedEvent(e.target.value)}
            >
              <option value="">Select an Event</option>
              {events.map((event) => (
                <option key={event.id} value={event.id}>
                  {event.event_name}
                </option>
              ))}
            </select>
          </div>

          {selectedEvent && (
            <>
              <div className="flex items-center justify-evenly space-x-4">
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={gender === 'male'}
                    onChange={() => setGender(gender === 'male' ? '' : 'male')}
                    className="form-checkbox h-5 w-5 text-blue-600 border-blue-600 focus:ring-blue-500"
                  />
                  <span className="ml-2 text-gray-800">Mardo Gate</span>
                </label>
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    checked={gender === 'female'}
                    onChange={() => setGender(gender === 'female' ? '' : 'female')}
                    className="form-checkbox h-5 w-5 text-pink-600 border-pink-600 focus:ring-pink-500"
                  />
                  <span className="ml-2 text-gray-800">Bairao Gate</span>
                </label>
              </div>

              <div>
                <input
                  type="tel"
                  ref={itsNumberInputRef}
                  className={`w-full px-3 py-2 border rounded-lg text-xl font-semibold tracking-wider focus:outline-none text-gray-700 ${
                    !itsNumber && 'bg-transparent text-gray-300'
                  }`}
                  placeholder="Enter ITS No."
                  value={itsNumber}
                  onChange={(e) => setItsNumber(e.target.value.replace(/[^0-9]/g, ''))}
                  maxLength="8"
                  inputMode="numeric"
                />
              </div>
            </>
          )}

          <button
            type="submit"
            disabled={isSubmitting || !selectedEvent || itsNumber.length !== 8}
            className="w-full bg-green-600 text-white py-2 rounded-lg hover:bg-green-700 transition duration-200 ease-in-out disabled:bg-gray-400 disabled:cursor-not-allowed"
          >
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
        </form>

        {submissionStatus && (
          <div className="text-center mt-4 p-4 bg-green-900 text-white rounded-lg shadow-lg">
            {mumininData && (
              <>
                <p className="flex justify-between">
                  <span>ITS:</span> <span>{mumininData.its}</span>
                </p>
                <p className="flex justify-between">
                  <span>Name:</span> <span>{mumininData.name}</span>
                </p>
                <p className="flex justify-between">
                  <span>Gender:</span> <span>{mumininData.gender}</span>
                </p>
              </>
            )}
          </div>
        )}

        <div className="bg-green-800 text-white p-4 font-bold rounded-lg mt-4 shadow-lg flex justify-between">
          <div className="flex flex-col items-center">
            <span className="text-3xl font-extrabold">{recordCounts.total_male_record}</span>
            <span className="text-base mt-2 font-normal">Mardo</span>
          </div>
          <div className="flex flex-col items-center">
            <span className="text-3xl font-extrabold">{recordCounts.total_female_record}</span>
            <span className="text-base mt-2 font-normal">Bairao</span>
          </div>
          <div className="flex flex-col items-center">
            <span className="text-3xl font-extrabold">{recordCounts.total_record}</span>
            <span className="text-base mt-2 font-normal">Total</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
